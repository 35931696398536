import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Relation from '@/dc-it/types/Relation'
import Customer from '@/dc-it/models/Customer'
import helpers from '@/dc-it/services/helpers'

export default class LoanRequest extends Model {
    endpoint = 'loan_requests'

    related = ['customer']

    fields = [
      new Field('id').hide(),
      new Relation('customer', 'Cliente', Customer, 'first_name')
        .applyMask((_val, model) => `${model.getAttr('first_name')} ${model.getAttr('last_name')}`),

      new Field('loan_amount', 'Monto del préstamo').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('term', 'Plazo').applySuffix(' meses'),
      new Field('rate', 'Tasa').applyMask(val => `${val * 100}%`),
      new Field('fixed_payment', 'Pago fijo mensual').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('created_at', 'Fecha de creación').applyMask(val => helpers.dateFormat(new Date(val), true)),
    ]

    clone=() => LoanRequest;
}
