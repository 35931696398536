import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class Customer extends Model {
    endpoint = 'customers';

    fields = [
      new Field('id', 'Código').disabled().hide(),
      new Field('reference', 'Referencia').disabled(),
      new Field('first_name', 'Nombre'),
      new Field('last_name', 'Apellido'),
      new Field('phone', 'Teléfono'),
      new Field('email', 'Correo electrónico'),
      new Field('curp', 'CURP'),
      new Field('rfc', 'RFC'),
      new Field('elector_key', 'Clave de elector'),
    ];

    clone = () => Customer;
}
